<template>
	<div>
		<!--面包屑-->
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>工作台</el-breadcrumb-item>
			<el-breadcrumb-item>任务查询</el-breadcrumb-item>
			<el-breadcrumb-item>任务详情</el-breadcrumb-item>
		</el-breadcrumb>
		<!--tab切换-->
		<el-tabs v-model="activeName" type="border-card" class="mt30">
			<el-tab-pane label="任务详情" name="first">
        <!--出票查看-->
				<TaskQueryDetailOne v-if="type == 'CP_CHECK'"
                            :bizInfo="bizInfo"
                            :otherInfo="otherInfo"
                            :invoiceInfo="invoiceInfo"
                            :contractInfo="contractInfo"
                            :maker="maker"
                            :acceptor="acceptor"
                            :taker="taker"
                            :ticketInfo="ticketInfo"
                            :ticketBaseInfoList="ticketBaseInfoList"/>
        <!--背书申请-->
        <EndorseApply v-if="type == 'BS_APPLY'"
                      :bizInfo="bizInfo"
                      :baseInfo="baseInfo"
                      :superInfoList="superInfoList"
                      :subInfoList="subInfoList"/>
        <!--背书撤销-->
        <EndorseRecall v-if="type == 'BS_CANCEL'"
                       :bizInfo="bizInfo"
                       :baseInfo="baseInfo"
                       :superInfoList="superInfoList"
                       :bssOperNo="bssOperNo"/>
        <!--背书应答-->
        <EndorseAnswer v-if="type == 'BS_RESP'"
                       :bizInfo="bizInfo"
                       :baseInfo="baseInfo"
                       :superInfoList="superInfoList"/>
        <!--信批出票-->
        <LetterApproval v-if="type == 'XP_CHECK'"
                        :letterApproval="letterApproval"/>
        <!--贴现申请-->
        <DiscountApply v-if="type == 'TX_CHECK'"
                       :discountApply="discountApply"/>
        <!--提示付款申请/提示付款撤销/提示付款应答 -->
        <TaskPayment v-if="type == 'FK_CHECK'"
                     :bizInfo="bizInfo"
                     :fksOperNo="fksOperNo"
                     :baseInfoList="baseInfoList"
                     :payeeInfo="payeeInfo"/>
        <!-- 保证申请/保证撤销/保证应答 -->
        <GuaranteeApplyLook v-if="type == 'BZ_CHECK'"
                            :bizInfo="bizInfo"
                            :operNo="operNo"
                            :baseInfoList="baseInfoList"
                            :ensureInfo="ensureInfo"/>
        <!-- 质押申请/质押撤销/质押应答 -->
        <PledgeLook v-if="type == 'ZY_APPLY' || type == 'ZY_CANCEL' || type == 'ZY_RESP'"
                    :bizInfo="bizInfo"
                    :operNo="operNo"
                    :baseInfoList="baseInfoList"
                    :subBaseInfoList="subBaseInfoList"
                    :pledgeInfoMore="pledgeInfoMore || {}"/>
        <!-- 质押解除申请/质押解除撤销/质押解除应答 -->
        <PledegeRecallLook v-if="type == 'JC_APPLY' || type == 'JC_CANCEL' || type == 'JC_RESP'"
                           :bizInfo="bizInfo"
                           :operNo="operNo  "
                           :baseInfoList="baseInfoList"
                           :subBaseInfoList="subBaseInfoList"
                           :dePledgeInfoMore="dePledgeInfoMore || {}"/>
			</el-tab-pane>
			<el-tab-pane label="任务流程" name="second">
				<TaskQueryDetailTwo :bizInfo="bizInfo"
                            :logInfoList="logInfoList"/>
			</el-tab-pane>
		</el-tabs>
		<el-button type="primary" class="goBack" @click="goBack">返回</el-button>
	</div>
</template>

<script>
  // 一期的第一个tab页
	import TaskQueryDetailOne from "./childCpn/TaskQueryDetailOne";
  // 背书申请(待背书复核，待背书申请登记，背书申请登记成功，待运营审核，关闭)
  import EndorseApply from "@/views/Ticket/routerCpn/workbench/taskQuery/second/EndorseApply";
  // 背书撤销(待申请背书撤销，待背书撤销登记，背书撤销登记成功（背书企业），背书撤销登记成功（被背书企业），待运营审核，关闭)
  import EndorseRecall from "@/views/Ticket/routerCpn/workbench/taskQuery/second/EndorseRecall";
  // 背书应答(待背书应答确认，待背书应答登记，背书应答登记成功（被背书企业），背书应答登记成功（背书企业），待运营审核，撤销)
  import EndorseAnswer from "@/views/Ticket/routerCpn/workbench/taskQuery/second/EndorseAnswer";
  // 信批出票(待应答，已完成，关闭)
  import LetterApproval from "@/views/Ticket/routerCpn/workbench/taskQuery/second/LetterApproval";
  // 贴现申请(待贴现复核，待运营审核，待财务审核，待贴现申请登记，待贴现应答，待贴现清算，贴现完成，关闭，待修改)
  import DiscountApply from "@/views/Ticket/routerCpn/workbench/taskQuery/second/DiscountApply";
  // 提示付款申请/提示付款撤销/提示付款应答
  import TaskPayment from '@/views/Ticket/routerCpn/workbench/taskQuery/third/TaskPayment.vue'
  // 保证申请/保证撤销/保证应答
  import GuaranteeApplyLook from '@/views/Ticket/routerCpn/workbench/taskQuery/four/GuaranteeApplyLook'
  // 质押申请/质押撤销/质押应答
  import PledgeLook from '@/views/Ticket/routerCpn/workbench/taskQuery/four/PledgeLook'
  // 质押解除申请/质押解除撤销/质押解除应答
  import PledegeRecallLook from '@/views/Ticket/routerCpn/workbench/taskQuery/four/PledegeRecallLook'
  // 任务流程 -- 公共部分
	import TaskQueryDetailTwo from "./childCpn/TaskQueryDetailTwo";
  export default {
    name: "TaskQueryDetail",
	  components: {
    TaskQueryDetailOne,
    EndorseApply,
    EndorseRecall,
    EndorseAnswer,
    LetterApproval,
    DiscountApply,
    TaskPayment,
    GuaranteeApplyLook,
    PledgeLook,
    PledegeRecallLook,
    TaskQueryDetailTwo,
},
	  mounted(){
      // 获取详情页数据
		  this.getDetailData()
	  },
	  data(){
      return {
        activeName: 'first',// 当前激活的tabs
        /* 控制展示详情页:
              CP_CHECK：出票查看 -- BS_APPLY：背书申请 -- BS_CANCEL：背书撤销 -- BS_RESP：背书应答 -- TX_CHECK：贴现查看 -- XP_CHECK：信批查看 
              FK_CHECK：付款查看 -- BZ_CHECK：保证查看 -- ZY_APPLY：质押申请 -- ZY_CANCEL：质押撤销 -- ZY_RESP：质押应答
              JC_APPLY：质押解除申请 -- JC_CANCEL：质押解除撤销 -- JC_RESP：质押解除应答
        */
        type: '',
	      // 请求参数
        searchForm: {
          ticketId: this.$route.query.ticketId, // 出票登记id
          handlerTypeCode: this.$route.query.handlerTypeCode //操作类型
        },
        bizInfo: {}, // 业务信息	-- 公共部分
        logInfoList: [], // 任务流程	-- 公共部分
        otherInfo:{}, // 出票查看 -- 其他信息
        invoiceInfo: {}, // 出票查看 -- 发票信息
        contractInfo: {}, // 出票查看 -- 合同信息
         maker: {}, // 出票查看 - 出票人 -- 后加的
        acceptor: {}, // 出票查看 - 承兑人 -- 后加的
        taker: {}, // 出票查看 - 收票人 -- 后加的
        ticketInfo: {}, // 出票查看 - 票据信息 -- 后加的
        ticketBaseInfoList: [], // 出票查看 - 票据基本信息 -- 后加的
        baseInfo: {}, // 背书申请/背书撤销 -- 背书信息
        superInfoList: [], // 背书申请/背书撤销 -- 母票基本信息
        subInfoList: [], // 背书申请 -- 子票基本信息
        bssOperNo: '', // 背书撤销 - 原背书申请操作编号
        letterApproval: {}, // 信批出票
        discountApply: {}, // 贴现申请
        fksOperNo: '', // 付款查看 -- 原提示付款申请操作编号
        baseInfoList: [], // 付款查看 -- 票据基本信息
        payeeInfo: {}, // 付款查看 -- 收款信息
        operNo: '', // 保证撤销 -- 原保证申请操作编号
        ensureInfo: {}, // 保证申请 -- 保证信息	
        subBaseInfoList: [], //质押管理 -- 子票基本信息	
        pledgeInfoMore: {}, // 质押管理 -- 质押信息	
        dePledgeInfoMore: {}, // 质押解除管理 -- 质押解除信息	
      }
	  },
    methods: {
      // 获取详情页数据
      async getDetailData(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/workbench/task/query/view/${this.searchForm.ticketId}/${this.searchForm.handlerTypeCode}`)
        if(res.code !== 200) return this.$message.error(res.msg);
        this.type = res.data.type;
        const {type} = this
        if (type == 'CP_CHECK' || type == 'BS_APPLY' || type == 'BS_CANCEL' || type == 'BS_RESP' || type == 'FK_CHECK' || type == 'BZ_CHECK' || type == 'ZY_APPLY' || type == 'ZY_CANCEL' || type == 'ZY_RESP' || type == 'JC_APPLY' || type == 'JC_CANCEL' || type == 'JC_RESP'){
          let {detail: {bizInfo, logInfoList, otherInfo, invoiceInfo, contractInfo, maker, acceptor, taker, ticketInfo, ticketBaseInfoList, baseInfo, superInfoList, subInfoList, bssOperNo, fksOperNo, baseInfoList, payeeInfo, operNo, ensureInfo, subBaseInfoList, pledgeInfoMore, dePledgeInfoMore}} = res.data
          this.bizInfo = bizInfo; // 业务信息	-- 公共部分
          this.logInfoList = logInfoList; // 任务流程	-- 公共部分
          this.otherInfo = otherInfo; // 出票查看 -- 其他信息
          this.invoiceInfo = invoiceInfo; // 出票查看 -- 发票信息
          this.contractInfo = contractInfo; // 出票查看 -- 合同信息
           // ------- 一期出票后加的，展示出票人、承兑人、收票人、票据信息，或者票据基本信息
          this.maker = maker
          this.acceptor = acceptor
          this.taker = taker
          this.ticketInfo = ticketInfo
          this.ticketBaseInfoList = ticketBaseInfoList
          // ------- 背书申请 --------
          this.baseInfo = baseInfo; // 背书申请 -- 背书信息
          this.superInfoList = superInfoList; // 背书申请 -- 母票基本信息
          this.subInfoList = subInfoList;// 背书申请 -- 子票基本信息
          // -------- 背书撤销 ---------
          this.bssOperNo = bssOperNo
          // -------- 付款查看 ---------
          this.fksOperNo = fksOperNo
          this.baseInfoList = baseInfoList
          this.payeeInfo = payeeInfo
          // -------- 保证查看 ----------
          this.operNo = operNo // 保证查看 -- 原保证申请操作编号
          this.ensureInfo = ensureInfo // 保证查看 -- 保证信息
          // -------- 质押管理 -----------
          this.subBaseInfoList = subBaseInfoList
          this.pledgeInfoMore = pledgeInfoMore
          // -------- 质押解除管理 ---------
          this.dePledgeInfoMore = dePledgeInfoMore
        }else if (type === 'TX_CHECK' || type === 'XP_CHECK'){
          // -------- 信批出票 ---------
          this.bizInfo = res.data.detail
          this.letterApproval = res.data.detail
          this.logInfoList = res.data.logInfoList
          // -------- 贴现申请 ----------
          this.bizInfo = res.data.detail
          this.discountApply = res.data.detail
          this.logInfoList = res.data.logInfoList
        }
      },
	    // 返回
      goBack(){
        this.$router.go(-1)
      }
    }
  }
</script>

<style scoped>
.goBack{
	display: block;
	width: 150px;
	margin: 60px auto 30px;
}
</style>