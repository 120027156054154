<template>
  <div>
    <el-card>
      <el-descriptions :column="3">
        <el-descriptions-item label="操作类型">{{bizInfo.handlerType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{bizInfo.taskNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{bizInfo.taskStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card>
      <el-descriptions title="背书信息" :column="2">
        <el-descriptions-item label="被背书人">{{baseInfo.compName}}</el-descriptions-item>
        <el-descriptions-item label="被背书人账号">{{baseInfo.acctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户行">{{baseInfo.acctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{baseInfo.acctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="背书金额">{{baseInfo.packageSum}}</el-descriptions-item>
        <el-descriptions-item label="申请日期">{{baseInfo.applyDate}}</el-descriptions-item>
        <el-descriptions-item label="转让标记">{{baseInfo.transferFlag}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{baseInfo.remark}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!--票据基本信息-->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="superInfoList">
        <template v-slot:titleText>
          <h3>票据基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="superInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
      </el-table>
    </el-card>
    <!-- 原背书申请操作编号 -->
    <el-card>
      <el-descriptions title="原背书申请操作编号">
        <el-descriptions-item label="操作编号">
          <span class="bssOperNo">{{bssOperNo}}</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
/*公共标题*/
import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
export default {
  name: "EndorseRecall",
  props: ['bizInfo','baseInfo', 'superInfoList', 'bssOperNo'],
  components: {
    WorkbenchPublicTitle
  }
}
</script>

<style lang="less" scoped>
  .el-descriptions{
    /deep/ .el-descriptions__header{
      margin-bottom: 10px;
    }
    /deep/ .el-descriptions__body{
      padding-left: 20px;
    }
    .bssOperNo{
      color: #c70009;
      font-weight: bold;
    }
  }
</style>