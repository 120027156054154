<template>
  <div>
    <el-card>
      <el-descriptions :column="3">
        <el-descriptions-item label="操作类型">{{letterApproval.operType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{letterApproval.operNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{letterApproval.handlerNodeStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!--信披验证票据信息-->
    <el-card>
      <div class="title-one">
        <span>信披验证票据信息</span>
        <span>温馨提示：此票仅用于信息披露平台绑定验证，到期自动清退，无需兑付。</span>
      </div>
      <ul class="list-special">
        <li>
          <span>签票验证票面金额（元）：</span>
          <em>{{letterApproval.verifyBillAmount}}</em>
        </li>
        <li>
          <span>出票日期：</span>
          <em>{{letterApproval.drawBillDate}}</em>
        </li>
        <li>
          <span>票据到期日：</span>
          <em>{{letterApproval.billDueDate}}</em>
        </li>
        <li>
          <span>票据种类：</span>
          <em>{{letterApproval.billType}}</em>
        </li>
      </ul>
    </el-card>
    <!--出票人-->
    <el-card>
      <h3>出票人</h3>
      <ul class="list">
        <li>
          <span>出票人：</span>
          <em>{{letterApproval.cprName}}</em>
        </li>
        <li>
          <span>出票人账号：</span>
          <em>{{letterApproval.cprAccountNo}}</em>
        </li>
        <li>
          <span>开户行：</span>
          <em>{{letterApproval.cprOpenBank}}</em>
        </li>
        <li>
          <span>开户行行号：</span>
          <em>{{letterApproval.cprOpenBankNo}}</em>
        </li>
      </ul>
    </el-card>
    <!--承兑人-->
    <el-card>
      <h3>承兑人</h3>
      <ul class="list">
        <li>
          <span>承兑人：</span>
          <em>{{letterApproval.cdrName}}</em>
        </li>
        <li>
          <span>承兑人账号：</span>
          <em>{{letterApproval.cdrAccountNo}}</em>
        </li>
        <li>
          <span>开户行：</span>
          <em>{{letterApproval.cdrOpenBank}}</em>
        </li>
        <li>
          <span>开户行行号：</span>
          <em>{{letterApproval.cdrOpenBankNo}}</em>
        </li>
      </ul>
    </el-card>
    <!--收票人-->
    <el-card>
      <h3>收票人</h3>
      <ul class="list">
        <li>
          <span>收票人：</span>
          <em>{{letterApproval.sprName}}</em>
        </li>
        <li>
          <span>收票人账号：</span>
          <em>{{letterApproval.sprAccountNo}}</em>
        </li>
        <li>
          <span>开户行：</span>
          <em>{{letterApproval.sprOpenBank}}</em>
        </li>
        <li>
          <span>开户行行号：</span>
          <em>{{letterApproval.sprOpenBankNo}}</em>
        </li>
      </ul>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "LetterApproval",
  props: ['letterApproval']
}
</script>

<style lang="less" scoped>
  .title-one{
    span{
      display: inline-block;
    }
    span:first-child{
      font-size: 16px;
      color: #24262B;
      font-weight: bold;
      margin-right: 10px;
    }
    span:nth-of-type(2){
      font-size: 12px;
      color: #666;
      i{
        color: #D6000F;
      }
    }
  }
  .list-special, .list{
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      margin-top: 20px;
      > span, label {
        display: inline-block;
        width: 180px;
        text-align: right;
      }
      span::before {
        display: inline-block;
        content: "*";
        color: #c70009;
        margin-right: 3px;
      }
      > .el-input{
        width: 50%;
      }
    }
  }
  .list{
    li{
      span{
        width: 100px;
      }
      /deep/ .el-select{
        width: 70%;
      }
    }
  }
</style>