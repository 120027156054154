<template>
  <div>
    <el-card>
      <el-descriptions :column="3">
        <el-descriptions-item label="操作类型">{{bizInfo.handlerType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{bizInfo.taskNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{bizInfo.taskStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 质押信息 -->
    <el-card>
      <el-descriptions title="质押信息" :column="2">
        <el-descriptions-item label="出质人">{{pledgeInfoMore.debtor}}</el-descriptions-item>
        <el-descriptions-item label="出质人账号">{{pledgeInfoMore.debtorAccNo}}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{pledgeInfoMore.debtorAcctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{pledgeInfoMore.debtorAcctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="质权人">{{pledgeInfoMore.creditor}}</el-descriptions-item>
        <el-descriptions-item label="质权人账号" v-if="pledgeInfoMore.creditorType == 'ENT'">{{pledgeInfoMore.creditorAcctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户银行" v-if="pledgeInfoMore.creditorType == 'ENT'">{{pledgeInfoMore.creditorAcctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{pledgeInfoMore.creditorAcctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="质押金额（元）">{{pledgeInfoMore.pledgeAmt}}</el-descriptions-item>
        <el-descriptions-item label="申请日期">{{pledgeInfoMore.applyDate}}</el-descriptions-item>
        <el-descriptions-item label="转让标记">{{pledgeInfoMore.transferFlag}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{handleRemark}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 票据基本信息：全质押 | 母票基本信息：拆分质押 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="baseInfoList">
        <template v-slot:titleText>
          <h3>{{titleName}}</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="baseInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="applyDate" label="出票日期"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketStatus" label="票据状态"></el-table-column>
      </el-table>
    </el-card>
    <!-- 子票基本信息 -->
    <el-card v-if="subBaseInfoList">
      <h3 class="mt30">子票基本信息</h3>
      <el-table :data="subBaseInfoList" border class="mt20">
        <el-table-column prop="subTicketInfo" label="子票信息"></el-table-column>
        <el-table-column prop="packageSum" label="子票包金额"></el-table-column>
        <el-table-column prop="transferFlag" label="转让标记"></el-table-column>
        <el-table-column prop="possessor" label="票据归属"></el-table-column>
      </el-table>
    </el-card>
    <!-- 原质押申请操作编号 -->
    <el-card v-if="operNo">
      <el-descriptions title="原质押申请操作编号" :column="2">
        <el-descriptions-item label="操作编号">
          <span class="operation-number">{{operNo}}</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  export default {
    name: 'PledgeLook',
    props: ['bizInfo', 'operNo', 'baseInfoList', 'subBaseInfoList', 'pledgeInfoMore'],
    components: {
      WorkbenchPublicTitle
    },
    computed: {
      // 备注如果为空显示无
      handleRemark(){
        if(this.pledgeInfoMore.remark == "" || this.pledgeInfoMore.remark == null){
          return '无'
        }else{
          return this.pledgeInfoMore.remark
        }
      },
      // 票据基本信息：全质押 | 母票基本信息：拆分质押
      titleName(){
        if(this.subBaseInfoList){
          return '母票基本信息'
        }else {
          return '票据基本信息'
        }
      }
    }
  }
</script>

<style lang="less">
  /deep/ .el-descriptions__body{
    padding-left: 20px;
  }
  // 操作编号
  .operation-number{
    color: #C70009;
    font-weight: bold;
  }
</style>