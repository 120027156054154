<template>
  <div>
    <el-card>
      <el-descriptions :column="3">
        <el-descriptions-item label="操作类型">{{discountApply.handlerType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{discountApply.taskNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{discountApply.taskStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <h3 class="mt20">贴现信息</h3>
    <ul class="discount-info-list">
      <li>
        <span>贴现机构：</span>
        <em>{{discountApply.orgFullCnName}}</em>
      </li>
      <li>
        <span>贴现行号： </span>
        <em>{{discountApply.orgBankCode}}</em>
      </li>
      <li>
        <span>贴现金额（元）：</span>
        <em>{{discountApply.discountAmount}}</em>
      </li>
      <li>
        <span>申请日期：</span>
        <em>{{discountApply.applyTime}}</em>
      </li>
      <li>
        <span>贴现利率（%）：</span>
        <em>{{discountApply.discountRate}}</em>
      </li>
      <li>
        <span>到账金额（元）：</span>
        <em>{{discountApply.arrivalAmount}}</em>
      </li>
      <li>
        <span>平台服务费率（%）：</span>
        <em>{{discountApply.platformServiceRate}}</em>
      </li>
      <li>
        <span>应付服务费（元）：</span>
        <em>{{discountApply.payServiceCost}}</em>
      </li>
      <li>
        <span>收款账号：</span>
        <em>{{discountApply.recBankAccount}}</em>
      </li>
      <li>
        <span>收款账户行号：</span>
        <em>{{discountApply.recLegalNumber}}</em>
      </li>
      <li>
        <span>转让标记：</span>
        <em>{{discountApply.transferFlagName}}</em>
      </li>
      <li>
        <span>收款账户银行：</span>
        <em>{{discountApply.recBankName}}</em>
      </li>
      <li>
        <span>贴现种类：</span>
        <em>{{discountApply.discountType}}</em>
      </li>
      <li>
        <span>结算方式：</span>
        <em>{{discountApply.settlementModel === 'ST01' ? '线上' : '线下'}}</em>
      </li>
      <li>
        <span>付息方式：</span>
        <em>{{discountApply.payInterestType}}</em>
      </li>
      <li>
        <label>备注：</label>
        <em>{{discountApply.remark}}</em>
      </li>
    </ul>
    <!--缴纳平台服务费-->
    <h3 class="mt20">缴纳平台服务费</h3>
    <ul class="discount-info-list">
      <li>
        <span>账户名称：</span>
        <em>{{discountApply.lxAccountName}}</em>
      </li>
      <li>
        <span>银行全称：</span>
        <em>{{discountApply.lxBankName}}</em>
      </li>
      <li>
        <span>银行账户：</span>
        <em>{{discountApply.lxBankAccount}}</em>
      </li>
      <li>
        <span>大额行号：</span>
        <em>{{discountApply.lxLegalNumber}}</em>
      </li>
      <li>
        <label>缴纳凭证：</label>
        <img :src="discountApply.payVouchersUrl" alt="" class="payVouchersUrl">
      </li>
      <li>
        <label>缴纳时间：</label>
        <em>{{discountApply.payTime}}</em>
      </li>
    </ul>
    <!--发票信息-->
    <h3 class="mt30">发票信息</h3>
    <el-table :data="discountApply.invoiceList" border class="mt20">
      <el-table-column label="序号" type="index" width="50px"></el-table-column>
      <el-table-column label="发票代码" prop="invoiceCode" min-width="120px"></el-table-column>
      <el-table-column label="发票号码" prop="invoiceNum" min-width="100px"></el-table-column>
      <el-table-column label="开票日期" prop="invoiceDate" min-width="100px"></el-table-column>
      <el-table-column label="价税合计（元）" prop="taxIncludedAmount" min-width="120px">
        <template v-slot="scope">
          <span>{{parseFloat(scope.row.taxIncludedAmount).toFixed(2)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="不含税金额（元）" prop="noTaxAmount" min-width="140px">
        <template v-slot="scope">
          <span>{{parseFloat(scope.row.noTaxAmount).toFixed(2)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="购买方" prop="purchaserName" min-width="120px"></el-table-column>
      <el-table-column label="销售方" prop="sellerName" min-width="120px"></el-table-column>
      <el-table-column label="后六位校验码" prop="checkCode" min-width="120px"></el-table-column>
      <el-table-column label="操作" fixed="right" min-width="100px">
        <template v-slot="scope">
          <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="bill-all">
      <span>价税合计（总计）：{{discountApply.taxTotal}}元</span>
      <span>发票张数：{{discountApply.invoiceTotal}}张</span>
    </div>
    <!--合同信息-->
    <el-descriptions title="合同信息" :column="2" class="mt30">
      <el-descriptions-item label="合同编号">{{discountApply.contractNo}}</el-descriptions-item>
      <el-descriptions-item label="合同日期">{{discountApply.contractSignDate}}</el-descriptions-item>
      <el-descriptions-item label="合同金额（元）">{{discountApply.contractAmt}}</el-descriptions-item>
      <el-descriptions-item label="合同信息分类">
        <span v-if="discountApply.tradeInfoType == 1">合同</span>
        <span v-else>订单</span>
      </el-descriptions-item>
      <el-descriptions-item label="贸易背景方式">
        <span v-if="discountApply.tradeMode == '1'">货物贸易</span>
        <span v-else-if="discountApply.tradeMode == '2'">服务贸易</span>
        <span v-else-if="discountApply.tradeMode == '3'">货服贸易</span>
      </el-descriptions-item>
      <el-descriptions-item label="合同附件">
         <a :href="discountApply.tradeFileUrl" target="_blank" class="accessory">{{discountApply.fileName}}</a>
      </el-descriptions-item>
    </el-descriptions>
    <!--母票基本信息-->
    <WorkbenchPublicTitle :superInfoList="discountApply.discountTicketInfoVOList">
      <template v-slot:titleText>
        <h3>母票基本信息</h3>
      </template>
    </WorkbenchPublicTitle>
    <el-table :data="discountApply.discountTicketInfoVOList" border class="mt20">
      <el-table-column prop="packageNo" label="票据包号"></el-table-column>
      <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
      <el-table-column prop="acceptorPersonName" label="承兑人"></el-table-column>
      <el-table-column prop="expireTime" label="票据到期日"></el-table-column>
      <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
    </el-table>
    <!--子票基本信息-->
    <template v-if="discountApply.isHaveChild === 1">
      <h3 class="mt30">子票基本信息</h3>
      <el-table :data="discountApply.ticketChildVOList" border class="mt20">
        <el-table-column prop="childTicketType" label="子票信息"></el-table-column>
        <el-table-column prop="ticketAffiliation" label="票据归属"></el-table-column>
        <el-table-column prop="childPackageSum" label="子票包金额"></el-table-column>
        <el-table-column prop="transferFlagName" label="转让标记"></el-table-column>
      </el-table>
    </template>
    <!--查看发票对话框-->
    <el-dialog :visible.sync="invoiceDialogVisible">
      <img :src="invoiceUrl" class="invoiceImg" alt="">
      <span slot="footer" class="dialog-footer">
		    <el-button @click="invoiceDialogVisible = false">关 闭</el-button>
		  </span>
    </el-dialog>
  </div>
</template>

<script>
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  export default {
    name: "DiscountApply",
    props: ['discountApply'],
    components: {
      WorkbenchPublicTitle
    },
    data(){
      return {
        invoiceDialogVisible: false, // 控制查看发票对话框的显示与隐藏,
        invoiceUrl:'' // 查看发票图片地址
      }
    },
    methods: {
      // 发票信息列表 - 查看
      lookInvoice(row){
        console.log(row)
        this.invoiceUrl = row.invoiceImgOssUrl
        this.invoiceDialogVisible = true
      },
    }
  }
</script>

<style lang="less" scoped>
  /*贴现信息*/
  .discount-info-list{
    display: flex;
    flex-wrap: wrap;
    li{
      width: 50%;
      margin-top: 20px;
    > span, label{
      display: inline-block;
      width: 150px;
      text-align: right;
    }
    span::before{
      display: inline-block;
      content: "*";
      color: #c70009;
      margin-right: 3px;
    }
    .payVouchersUrl{
      width: 100px;
      vertical-align: middle;
    }
  }
  /*缴纳凭证*/
  /deep/ .upload-demo{
    display: inline-block;
    }
  }
    /*标题*/
  .title{
    h3{
      display: inline-block;
      margin-right: 10px;
    }
  }
  .bill-all{
    padding: 15px 20px;
    border: 1px solid #EBEEF5;
    border-top: none;
    span{
      display: inline-block;
      font-size: 14px;
      color: #666;
      margin-top: 10px;
    }
    span:first-child{
      margin-right: 40px;
    }
  }
  .accessory{
    color: #c70009;
  }
  .invoiceImg{
    width: 100%;
    height: 100%;
  }
  // 描述
  .el-descriptions__body{
    padding-left: 20px;
  }
</style>