<template>
  <div>
    <el-card>
      <el-descriptions :column="3">
        <el-descriptions-item label="操作类型">{{bizInfo.handlerType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{bizInfo.taskNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{bizInfo.taskStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 票据基本信息 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="baseInfoList">
        <template v-slot:titleText>
          <h3>票据基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="baseInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
      </el-table>
    </el-card>
    <!-- 收款信息 -->
    <el-card>
      <el-descriptions title="收款信息" :column="2">
        <el-descriptions-item label="收款人">{{payeeInfo.compName}}</el-descriptions-item>
        <el-descriptions-item label="收款账号">{{payeeInfo.acctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户行">{{payeeInfo.acctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{payeeInfo.acctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="清算方式">{{payeeInfo.clrWay}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 原提示付款申请操作编号 -->
    <el-card v-if="fksOperNo !== null">
      <el-descriptions title="原提示付款申请操作编号" :column="2">
        <el-descriptions-item label="操作编号">
          <span class="operation-number">{{fksOperNo}}</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  export default {
    name: 'TaskPayment',
    props: ['bizInfo', 'fksOperNo', 'baseInfoList', 'payeeInfo'],
    components: {
      WorkbenchPublicTitle
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .el-descriptions__body{
    padding-left: 20px;
  }
  // 操作编号
  .operation-number{
    color: #C70009;
    font-weight: bold;
  }
</style>